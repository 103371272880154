import React from 'react';
import './footer.scss';
import { Link } from 'react-router-dom';

function Footer() {
  return (
    <>
        <footer className="footer">
            <div className="footer__content">
                <span className="footer__content--email">heiki.kotsar@voco.ee</span>
            </div>
        </footer>
    </>
  )
}

export default Footer
