import React from 'react';
import NotFound from '../components/notFound/NotFound';

function NotFoundPage() {
  return (
    <>
      <NotFound message={"Lehekülge ei leitud."} />
    </>
  )
}

export default NotFoundPage
